.Display {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Display--container {
    border: 3px solid #2992D8;
    width: 60%;
    height: 70%;
}

.Display--info {
    width: 900px;
    font-size: 10pt;
    color: rgb(0, 0, 0);
}

@media only screen and (max-width:1500px) {
    .Display--container {
        width: 90%;
    }

    .Display--info {
        width: 90%;
    }
}

@media only screen and (max-width:900px) {
    .Display {
        border-bottom: 1px solid rgb(26, 135, 143);
        padding-bottom: 10px;
    }

    .Display--container {
        height: 300px;
        margin: 10px;
    }
}