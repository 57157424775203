.ImageListInputItem {
    cursor: pointer;
    margin: 5px;
    background-color: #0082ce;
}

.ImageListInputItem:hover {
    background-color: #0082ce;
} 

.ImageListInputItem--img {
    border: 3px solid transparent;
    margin: 0;
    padding: 0;
    display: block;
    width: 75px;
}

.ImageListInputItem--img-selected {
    border: 3px solid rgb(255, 92, 0);
    margin: 0;
    padding: 0;
    display: block;
    width: 75px;

}

.ImageListInputItem--caption {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 10pt;
}