.SliderInput {
    display: flex;
    align-items: center;
    margin-top: 25px;
}

.SliderInput--label {
    font-weight: bold;
    margin-right: 5px;
}

.SliderInput--value {
    margin-left: 5px;
    background-color: #0082ce;
    padding: 5px;
    
}