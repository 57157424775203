.ArrayInput--header {
    display: flex;
    align-items: center;
    color:white;
    background-color: #0082ce;
    padding: 10px;
    border-radius: 10px;
}

.ArrayInput--header-button {
    cursor: pointer;
}

.ArrayInput--header-label {
    margin-left: 20px;
    margin-right: 10px;
    font-weight: bold;
}

.ArrayInput--content {
    margin-top: 20px;
}