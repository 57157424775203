.Settings {
    background: #01599340;
    width: 500px;
    overflow-y: scroll;
}

@media only screen and (max-width:900px) {
    .Settings {
        width: 100%;
        overflow-y: auto;
    }
}