.App {
  flex: 1;  
  display: flex;
  font-family: 'Poppins', sans-serif;

}

.swal2-styled.swal2-confirm{
  background-color: #2992D8 !important;
}

.swal2-title{
  font-size: 18px !important;
}

.swal2-container{
  font-family: 'Poppins', sans-serif !important; 
}

.swal2-input{
  height: 1.625em !important;
  font-size: 15px !important;
  width: 400px !important;
}

body.swal2-shown > [aria-hidden="true"] {
  transition: 0.5s filter;
  filter: blur(20px);
}

.ArrayInput{
 color: black !important
}

@media only screen and (max-width:900px) {
  .App {
    flex-direction: column;
  }
}