.SelectInput {
    margin-right: 20px;
    margin-bottom: 10px;
}

.SelectInput--label {
    margin-right: 5px;
    font-weight: bold;
}

.SelectInput--select {
    background-color: white;
    padding: 5px;
    font-size: 12pt;
    cursor: pointer;
    border: 2px solid gray;
    font-weight: bold;
}

.SelectInput--unit {
    margin-left: 5px;
}