.DoorInput {
    background-color:  #00659f;
    margin-top: 20px;
    padding: 10px;
    position: relative;
    border-radius: 10px;
    color:white;
}

.DoorInput--remove {
    position: absolute;
    top: 0;
    right: 0;
    margin: 20px;
    cursor: pointer;
    opacity: 0.7;
}

.DoorInput--remove:hover {
    opacity: 1;
}
