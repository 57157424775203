.NumberInput {
    margin-right: 20px;
}

.NumberInput--label {
    margin: 5px;
}

.NumberInput--input {
    background-color: white;
    padding: 5px;
    font-size: 12pt;
    cursor: pointer;
    border: 2px solid gray;
    font-weight: bold;
}

.NumberInput--unit {
    margin-left: 5px;
}