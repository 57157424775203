.ImageListInput {
    margin-top: 20px;
}

.ImageListInput--label {
    font-weight: bold;
}

.ImageListInput--content {
    background-color: #0082ce;
    display: flex;
    margin: 5px;
    flex-wrap: wrap;
}