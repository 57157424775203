html {
  height: 100%;
}

body {
  margin: 0;
  background-color: #ffffff;
  height: 100%;
  display: flex;
  background-image: url("https://i.ibb.co/26RNfpM/tile-background.png");
  background-position: cover;
}

#root {
  flex: 1;
  display: flex;
}