.SettingsItem {
    background-color: #0082ce;
    padding: 10px;
    margin: 10px 20px;
    border-radius: 10px;
    color: white;
}

.SettingsItem--send {
    background-color: #004c79;
    padding: 10px;
    margin: 10px 20px;
    border-radius: 10px;
    color: white;
}

.SettingsItem--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 10px;
}

.SettingsItem--header-text {
    font-size: 10pt;
    font-weight: bold;
}

.SettingsItem--header-dropdown {
    cursor: pointer;
    opacity: 0.5;
    color: white;
}

.SettingsItem--header-dropdown:hover {
    opacity: 1;
}

.SettingsItem--content {
    margin: 15px 5px;
}